@import './constants/colors.scss';

body, a, button {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  color: $white;
  font-family: 'Playfair Display'!important;
}

body {
  background-color: $primary;
  padding: 0!important;
}

.main {
  background-color: white;
}

body.menu-open {
  overflow: hidden!important;
}

p {
  margin: 0;
}

.container {
  margin: 0 auto;
  max-width: 1160px;
  height: 100%;

  @media screen and (max-width: 1200px) {
    padding: 0 40px;
  }

  @media screen and (max-width: 600px) {
    padding: 0 20px;
  }
}

.df {
  display: flex;
}

.df-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gradient-background {
  background: linear-gradient(180deg, #FFFFFF 0%, #EBEBEB 100%);
  background-blend-mode: multiply;
  mix-blend-mode: normal;
}

h1 {
  font-weight: 700;
  font-size: 46px;
  line-height: 56px;

  @media screen and (max-width: 600px) {
    font-size: 28px;
    line-height: 46px;
  }
}

h2 {
  font-size: 36px;
  line-height: 56px;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0;

  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 27px;
  }
}

.MuiFormLabel-root, .MuiSelect-root, .MuiInput-input, .MuiListItem-root, .MuiInputBase-root {
  font-family: "Playfair Display"!important;
}