$primary: #165153;
$primary2: #DAAB47;
$white: #FFFFFF;
$grey-dark: #979797;
$grey-light: #E0E0E0;
$bronze: #B57D30;
$black: #000000;
$txt-primary: #353E4A;
$dark-green: #002B2E;
$gradient-light: linear-gradient(180deg, #FFFFFF 0%, #EBEBEB 100%);
$gradient-dark: linear-gradient(180deg, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0) 100%);